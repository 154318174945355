import { DrawerProps, Theme } from '@mui/material'
import { AgentProduct } from '~/configs/AgentProduct'
import lightTheme, { futuresaiTheme } from '~/configs/lightTheme'
import { GA_TRACKING_ID } from '~/configs/GA_TRACKING_ID'
import darkTheme from '~/configs/darkTheme'
import { meGetAgentName } from '~/modules/SDK/me/meGetAgentName'

const googleAnalyticsDefaults: AgentConfig2['googleAnalytics'] = {
  enabled: true,
  analyticsID: GA_TRACKING_ID,
}

export const agentConfigs2: {
  [key in AgentProduct]: AgentConfig2
} = {
  null: {
    name: 'null',
    title: '',
    favicon: '/null/icon.png',
    emailSuffix: '@null',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
      trialCode: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'sungop@web': {
    name: meGetAgentName(AgentProduct['sungop@web']),
    title: 'sungop',
    favicon: '/sungop/icon.png',
    emailSuffix: '@sungop.futures-ai.com',
    theme: futuresaiTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
      trialCode: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'futuresai@web': {
    name: meGetAgentName(AgentProduct['futuresai@web']),
    title: '期天大勝',
    favicon: '/futuresai/icon.png',
    emailSuffix: '@futures-ai.com',
    theme: futuresaiTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
      trialCode: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'futuresai@admin': {
    name: meGetAgentName(AgentProduct['futuresai@admin']),
    title: '期天管理後台',
    favicon: '/futuresai/icon.png',
    emailSuffix: '@admin.futures-ai.com',
    theme: futuresaiTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: true,
      trialCode: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'futuresai@exchange': {
    name: meGetAgentName(AgentProduct['futuresai@exchange']),
    title: '期天大勝',
    favicon: '/futuresai/icon.png',
    emailSuffix: '@futures-ai.com',
    theme: futuresaiTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'futuresai@strategy_center': {
    name: meGetAgentName(AgentProduct['futuresai@strategy_center']),
    title: '期天大勝',
    favicon: '/futuresai/icon.png',
    emailSuffix: '@futures-ai.com',
    theme: futuresaiTheme,
    loginApproachAllow: {
      thirdparty: false,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'hyt888@web': {
    name: meGetAgentName(AgentProduct['hyt888@web']),
    title: '股海淘金',
    favicon: '/hyt888/icon-medium.png',
    emailSuffix: '@hyt888.futures-ai.com',
    theme: darkTheme,

    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'hyt888@web_stock': {
    name: meGetAgentName(AgentProduct['hyt888@web_stock']),
    title: '股海淘金股票版',
    favicon: '/hyt888/icon-medium.png',
    emailSuffix: '@hyt888.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'good178@web': {
    name: meGetAgentName(AgentProduct['good178@web']),
    title: 'MIS 專攻期貨版',
    favicon: '/good178/favicon.png',
    emailSuffix: '@good178.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'good178@web_stock': {
    name: meGetAgentName(AgentProduct['good178@web_stock']),
    title: 'MIS 專攻股票版',
    favicon: '/good178/favicon.png',
    emailSuffix: '@good178.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'weng888@web': {
    name: meGetAgentName(AgentProduct['weng888@web']),
    title: '股期大富翁',
    favicon: '/weng888/favicon.png',
    emailSuffix: '@weng888.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'weng888@web_stock': {
    name: meGetAgentName(AgentProduct['weng888@web_stock']),
    title: '股市大富翁',
    favicon: '/weng888/favicon.png',
    emailSuffix: '@weng888_stock.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'asiajye@web': {
    name: meGetAgentName(AgentProduct['asiajye@web']),
    title: '大探極系統-期貨版',
    favicon: '/asiajye/favicon.png',
    emailSuffix: '@asiajye.futures-ai.com',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'asiajye@web_stock': {
    name: meGetAgentName(AgentProduct['asiajye@web_stock']),
    title: '大探極系統-股票版',
    favicon: '/asiajye_stock/favicon.png',
    emailSuffix: '@asiajye_stock.futures-ai.com',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'san@web': {
    name: meGetAgentName(AgentProduct['san@web']),
    title: '勝期士',
    favicon: '/tbd-favicon.png',
    emailSuffix: '@san.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'bole@web_stock': {
    name: meGetAgentName(AgentProduct['bole@web_stock']),
    title: '伯樂操盤系統',
    favicon: '/bole/favicon.png',
    emailSuffix: '@bole_stock.futures-ai.com',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'winner@web': {
    name: meGetAgentName(AgentProduct['winner@web']),
    title: '星鑽領航系統',
    favicon: '/winner-favicon.png',
    emailSuffix: '@winner.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'winner@web_A': {
    name: meGetAgentName(AgentProduct['winner@web_A']),
    title: '星鑽領航系統',
    favicon: '/winner/winnerA/favicon.png',
    emailSuffix: '@winner.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  //--
  'winner@web_B': {
    name: meGetAgentName(AgentProduct['winner@web_B']),
    title: '星鑽領航系統-台指進階',
    favicon: '/winner/winnerB/favicon.png',
    emailSuffix: '@winner.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'winner@web_C': {
    name: meGetAgentName(AgentProduct['winner@web_C']),
    title: '星鑽領航系統-台指籌碼',
    favicon: '/winner/winnerC/favicon.png',
    emailSuffix: '@winner.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'moneycom@web': {
    name: meGetAgentName(AgentProduct['moneycom@web']),
    title: '投信投顧官網',
    favicon: '/moneycom/dev-cover.jpg',
    emailSuffix: '',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'winner98@web': {
    name: meGetAgentName(AgentProduct['winner98@web']),
    title: 'AI WINNER',
    favicon: '/winner98/favicon3.png',
    emailSuffix: '@winner98.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'winner98@web_stock': {
    name: meGetAgentName(AgentProduct['winner98@web_stock']),
    title: '股市[天羅帝王]黑科技',
    favicon: '/winner98/favicon1.png',
    emailSuffix: '@winner98.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'winner98@backtest': {
    name: meGetAgentName(AgentProduct['winner98@backtest']),
    title: '天羅帝王策略回測',
    favicon: '/winner98/favicon1.png',
    emailSuffix: '@winner98.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'winner98@crypto': {
    name: meGetAgentName(AgentProduct['winner98@crypto']),
    title: '交易觀測站',
    favicon: '',
    emailSuffix: '@winner98.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'morerich@web': {
    name: meGetAgentName(AgentProduct['morerich@web']),
    title: '摩爾投顧指標測試網',
    favicon: '/morerich/favicon.png',
    emailSuffix: '@morerich.futures-ai.com',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'win168@web': {
    name: meGetAgentName(AgentProduct['win168@web']),
    title: '聚寶期',
    favicon: '/win168/favicon.ico',
    emailSuffix: '@win168.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'richadam@web': {
    name: meGetAgentName(AgentProduct['richadam@web']),
    title: '出期致勝',
    favicon: '/richadam/favicon.ico',
    emailSuffix: '@richadam.futures-ai.com',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'richadam@web_stock': {
    name: meGetAgentName(AgentProduct['richadam@web_stock']),
    title: '出期致勝-股票版',
    favicon: '/richadam/favicon.png',
    emailSuffix: '@richadam.futures-ai.com',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'ai919@web': {
    name: meGetAgentName(AgentProduct['ai919@web']),
    title: '絕對AI領域',
    favicon: '/ai919/favicon.png',
    emailSuffix: '@ai919.futures-ai.com',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'goldbrain@web': {
    name: meGetAgentName(AgentProduct['goldbrain@web']),
    title: 'AI金頭腦操作沒煩腦-神乎期技',
    favicon: '/goldbrain/favicon.png',
    emailSuffix: '@goldbrain.futures-ai.com',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'goldbrain@web_stock': {
    name: meGetAgentName(AgentProduct['goldbrain@web_stock']),
    title: 'AI金頭腦操作沒煩腦-智能選飆股',
    favicon: '/goldbrain/favicon.png',
    emailSuffix: '@goldbrain.futures-ai.com',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'blue168@web': {
    name: meGetAgentName(AgentProduct['blue168@web']),
    title: '關鍵K系統',
    favicon: '/blue168/favicon.png',
    emailSuffix: '@blue168.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'lung88988@web_stock': {
    name: meGetAgentName(AgentProduct['lung88988@web_stock']),
    title: 'SUPERSTOCK動能操盤系統',
    favicon: '/lung88988/favicon1.png',
    emailSuffix: '@lung88988.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'lung88988@web': {
    name: meGetAgentName(AgentProduct['lung88988@web']),
    title: 'SUPERSTOCK動能操盤系統-期貨版',
    favicon: '/lung88988/favicon1.png',
    emailSuffix: '@lung88988.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'sinopac@web': {
    name: meGetAgentName(AgentProduct['sinopac@web']),
    title: '永豐期貨策略平台',
    favicon: '/sinopac2/favicon.png',
    emailSuffix: '@sinopac.futures-ai.com',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: true,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'jihsun@web': {
    name: meGetAgentName(AgentProduct['jihsun@web']),
    title: '日盛專業版',
    favicon: '/jihsun/favicon.png',
    emailSuffix: '@jihsun.futures-ai.com',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: false,
      password: true,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'fubon@web': {
    name: meGetAgentName(AgentProduct['fubon@web']),
    title: '富邦期貨趨勢贏家指標',
    favicon: '/fubon/favicon.png',
    emailSuffix: '@fubon.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: false,
      password: true,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'huanan@web': {
    name: meGetAgentName(AgentProduct['huanan@web']),
    title: '_',
    favicon: '/huanan/favicon.png',
    emailSuffix: '@huanan.futures-ai.com',
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  't3j@web': {
    name: meGetAgentName(AgentProduct['t3j@web']),
    title: 't3j交易',
    favicon: '/t3j/favicon.png',
    emailSuffix: '@t3j.futures-ai.com',
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'kabujukuTW@web_stock': {
    name: meGetAgentName(AgentProduct['kabujukuTW@web_stock']),
    title: '股票道場',
    favicon: '/kabujukuTW/favicon.png',
    emailSuffix: '@kabujukuTW.futures-ai.com',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'ok1788@web_stock': {
    name: meGetAgentName(AgentProduct['ok1788@web_stock']),
    title: '股市易點靈',
    favicon: '/ok1788/favicon.png',
    emailSuffix: '@ok1788.futures-ai.com',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  's178178@web': {
    name: meGetAgentName(AgentProduct['s178178@web']),
    title: '繽紛大未來',
    favicon: '/s178178/logo.png',
    emailSuffix: '@s178178.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'mvp888@web_stock': {
    name: meGetAgentName(AgentProduct['mvp888@web_stock']),
    title: '金神準系統',
    favicon: '/mvp888/favicon.png',
    emailSuffix: '@mvp888.futures-ai.com',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'm168168@web_stock': {
    name: meGetAgentName(AgentProduct['m168168@web_stock']),
    title: '外資超錢線',
    favicon: '/m168168/favicon.png',
    emailSuffix: '@m168168.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'eurex@web': {
    name: meGetAgentName(AgentProduct['eurex@web']),
    title: 'Eurex 歐交所商品觀測網站',
    favicon: '/eurex/favicon.ico',
    emailSuffix: '@eurex.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'big3@web': {
    name: meGetAgentName(AgentProduct['big3@web']),
    title: 'big3',
    favicon: '/big3/favicon1.png',
    emailSuffix: '@big3.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'big3@web_stock': {
    name: meGetAgentName(AgentProduct['big3@web_stock']),
    title: 'big3_stock',
    favicon: '/big3/favicon1.png',
    emailSuffix: '@big3.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'imoney8888@web_stock': {
    name: meGetAgentName(AgentProduct['imoney8888@web_stock']),
    title: '神雞妙算',
    favicon: '/imoney8888/favicon.png',
    emailSuffix: '@imoney8888.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'yaya168@web_stock': {
    name: meGetAgentName(AgentProduct['yaya168@web_stock']),
    title: '股海飛揚',
    favicon: '/yaya168/favicon1.png',
    emailSuffix: '@yaya168.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'gd1788@web': {
    name: meGetAgentName(AgentProduct['gd1788@web']),
    title: '股海飛揚',
    favicon: '/gd1788/favicon1.png',
    emailSuffix: '@gd1788.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'south@web': {
    name: meGetAgentName(AgentProduct['south@web']),
    title: '南哥量化選股',
    favicon: '/south/favicon1.png',
    emailSuffix: '@south.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'gorich55688@web_stock': {
    name: meGetAgentName(AgentProduct['gorich55688@web_stock']),
    title: 'gorich55688',
    favicon: '/gorich55688/favicon1.png',
    emailSuffix: '@gorich55688.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'jerry597@web_stock': {
    name: meGetAgentName(AgentProduct['jerry597@web_stock']),
    title: '金旺旺',
    favicon: '/jerry597/favicon1.png',
    emailSuffix: '@jerry597.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'daddy960@web_goodway': {
    name: meGetAgentName(AgentProduct['daddy960@web_goodway']),
    title: '股威市長',
    favicon: '/goodway/favicon.png',
    emailSuffix: '@daddy960.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'daddy960@web_stocknerve': {
    name: meGetAgentName(AgentProduct['daddy960@web_stocknerve']),
    title: '做股神經',
    favicon: '/stocknerve/favicon.png',
    emailSuffix: '@daddy960.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'daddy960@web_opkevin': {
    name: meGetAgentName(AgentProduct['daddy960@web_opkevin']),
    title: 'opkevin',
    favicon: '/opkevin/favicon.png',
    emailSuffix: '@daddy960.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'daddy960@web_tc1688': {
    name: meGetAgentName(AgentProduct['daddy960@web_tc1688']),
    title: 'tc1688',
    favicon: '/tc1688/favicon.png',
    emailSuffix: '@daddy960.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'daddy960@web_revive': {
    name: meGetAgentName(AgentProduct['daddy960@web_revive']),
    title: 'revive',
    favicon: '/revive/favicon.png',
    emailSuffix: '@daddy960.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'god@web': {
    name: meGetAgentName(AgentProduct['god@web']),
    title: '鱷魚老師看盤',
    favicon: '/god/favicon1.png',
    emailSuffix: '@god.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'david0705@web': {
    name: meGetAgentName(AgentProduct['david0705@web']),
    title: '全視角守護神',
    favicon: '/david0705/favicon.png',
    emailSuffix: '@david0705.futures-ai.com',
    theme: darkTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'asia71178@web': {
    name: meGetAgentName(AgentProduct['asia71178@web']),
    title: '台股期蹟',
    favicon: '/asia71178/favicon.png',
    emailSuffix: '@asia71178.futures-ai.com',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'stanli@web': {
    name: meGetAgentName(AgentProduct['stanli@web']),
    title: 'stanli',
    favicon: '/stanli/favicon.png',
    emailSuffix: '@stanli.futures-ai.com',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'datatree@web': {
    name: meGetAgentName(AgentProduct['datatree@web']),
    title: 'Datatree',
    favicon: '/datatree/favicon.png',
    emailSuffix: '@datatree.futures-ai.com',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'win988@web': {
    name: meGetAgentName(AgentProduct['win988@web']),
    title: '領航者',
    favicon: '/win988/logo.png',
    emailSuffix: '@win988.futures-ai.com',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'rich888@web_stock': {
    name: meGetAgentName(AgentProduct['rich888@web_stock']),
    title: '股市大超人 super man',
    favicon: '/rich888/favicon.png',
    emailSuffix: '@rich888.futures-ai.com',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
  'wu5868@web_stock': {
    name: meGetAgentName(AgentProduct['wu5868@web_stock']),
    title: '展哥選股',
    favicon: '/wu5868/favicon.png',
    emailSuffix: '@wu5868.futures-ai.com',
    theme: lightTheme,
    loginApproachAllow: {
      thirdparty: true,
      password: false,
    },
    googleAnalytics: googleAnalyticsDefaults,
  },
} as const

export type AgentConfig2 = {
  /** The name of the build, migrated from the old repo/routes.js */
  name: string
  /** The title inside `<NextHead>`, e.g. `<NextHead>{title}</NextHead>` */
  title: string
  /** E.g. `'/futuresai/icon.png'` */
  favicon: string
  /** E.g. `'@futuresai.com'` */
  emailSuffix: string
  /** @deprecated 這套設計出來後，大伙沒有共識，在隨後加零有一套新的 theme 模式，遵守她的，勿多頭馬車 */
  theme?: Theme

  /**
   * 每個 agent 可享有獨立的 Google Analytics 設定
   *
   * @default 預設使用期天主站的 Google Analytics 設定
   */
  googleAnalytics: {
    enabled: boolean
    analyticsID: string
  }

  /** UI 介面顯示設定 */
  loginApproachAllow: {
    /** 是否顯示「透過 facebook 或 google 登入」登入按鈕 */
    thirdparty: boolean
    /** 是否顯示「透過密碼登入」表單框 */
    password: boolean
    /** # @deprecated {@link WhyThisDeprecated.TrialCode} */
    trialCode?: boolean
  }
}
